/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "../../@core/scss/base/bootstrap-extended/include";
@import "../../@core/scss/base/components/include";

.project-modal .project-label.form-label {
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 0.857rem;
}

// ** Line Skelaton Loader
.lineSkelaton {
  position: relative;
  background-color: #f8f8f824;
  width: 100%;
  height: 100%;
  min-width: 50px;
  min-height: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
  border-radius: 0.428rem;
  margin-bottom: 10px;
}

.lineSkelaton::after {
  display: block;
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.35), transparent);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* that is currently Firefox-only */
// * {
//   scrollbar-width: thin;
//   scrollbar-color: $primary;
// }

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #feede7;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 10px;
}

.navbar-project-management {
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px;
    transition: all 4s linear;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 8px !important;
      height: 8px;
    }
  }
}
/* end scrollbar */

.cutom_StripeCard {
  border: 1px solid #404656;
  padding: 13.5px 15px;
  border-radius: 4px;
}

.nav-bar.navbar-wrap {
  padding-top: 1.5rem;
}

// Nav
.nav-bar .container-fluid {
  padding-left: 0;
  padding-right: 0px;

  .logo {
    text-align: center;
  }
}

.dropdoen-menu-filter {
  height: auto;
  max-height: 550px;
  // overflow-x: hidden;
  overflow-y: auto;
}

// a {
//   :hover {
//     color: $primary;
//     transition: all 0.2s ease-out;
//   }
// }
.invalid-feedback {
  // text-transform: capitalize;
  display: block !important;
  // margin-bottom: 5px;
}

//** Hamburger

.hamburger {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 2rem;
  justify-content: space-around;
  margin-right: 8px !important;
  padding: 0;
  width: 2rem;
  z-index: 10;

  & :focus {
    outline: none;
  }

  & div {
    background-color: $primary;
    height: 0.18rem;
    position: relative;
    -webkit-transform-origin: 1px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    width: 2rem;
  }
}

.hamburger div:first-child {
  transform: rotate(0deg);
}

.hamburger div:nth-child(2) {
  transform: translateX(0);
}

.hamburger div:nth-child(3) {
  transform: rotate(0deg);
}

.hamburger div.isOpen:first-child {
  transform: rotate(45deg);
}

.hamburger div.isOpen:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.hamburger div.isOpen:nth-child(3) {
  transform: rotate(-45deg);
}

// ? hamburger end

.vertical-layout.vertical-menu-modern .toggle-icon,
[dir="ltr"] .vertical-layout.vertical-menu-modern .collapse-toggle-icon {
  margin-right: 0 !important;
}

.main-menu .navbar-header .navbar-brand .brand-text {
  color: #fff;
}

//** NavLinks
.nav-link {
  padding: 0.358rem 0rem;
  display: block;
  color: $primary;
  transition: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-right: 32px;
}

// About DropDown
.menu-Dropdown {
  color: #ffffff;
  transition: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 0;
  padding: 0 0;
}

.menu-Dropdown.btn-flat-primary:hover:not(.disabled):not(:disabled),
.menu-Dropdown.btn-flat-primary:active {
  background-color: transparent;
  color: #ffffff;
}

.menu-Dropdown.btn-flat-primary.dropdown-toggle::after {
  background-image: url(../images/svg/downicon.svg);
}

.header-btn {
  background: $primary;
  box-shadow: 0px 4px 16px rgba(159, 234, 250, 0.12);
  border-radius: 100px;
  border: 0;
  color: $white !important;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
}

.themeBtn.btn-primary {
  // background: #f8a78c;
  box-shadow: 0px 4px 16px rgba(159, 234, 250, 0.12) !important;
  border-radius: 100px;
  border: 0;
  color: $white !important;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  min-width: 140px;
  min-height: 52px;

  // border-color: #f8a78c !important;
  // background-color: #f8a78c !important;
}

.pass-info.input-group-text {
  background: transparent;
  border: 0;
  text-align: end;
  position: absolute;
  top: 0;
  right: 6px;
  padding: 0 0;
}

.LoginFooter {
  .f-title {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    // color: #ffffff;
    color: $black;
    margin: 0 0 24px;
  }

  .f-logo p {
    margin-top: 24px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
  }

  .address {
    margin-bottom: 24px;
  }

  .address p,
  .contact-info p {
    margin: 0 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    // color: #ffffff;
    color: $black;
  }

  .support-links {
    li {
      margin-top: 24px;

      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        // color: #ffffff;
        color: $black;
      }
    }
  }

  .Subscribe-bk {
    margin-bottom: 40px;
  }

  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #cccccc;
    display: block;
  }

  .form-control {
    min-height: 50px;
    // border: 1px solid #435f86;
    border-radius: 12px;
    background-color: transparent !important;
    display: inline-flex;
    width: 100%;
    max-width: 441px;
  }

  .btn {
    background: #f8a78c;
    box-shadow: 0px 4px 16px rgba(159, 234, 250, 0.12);
    border-radius: 100px;
    border: 0;
    min-height: 50px;
    color: #0b1e38 !important;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    margin-left: 12px;
  }

  .footer-links li {
    display: inline-flex;
    margin-right: 20px;

    a {
      // color: #b3b3b3;
      color: $black;
    }
  }

  .copyright svg {
    margin-right: 5px;
  }

  .footer-links hr {
    width: 1px;
    margin: 0 0;
    display: inline-flex;
    height: 10px;
    position: absolute;
    margin-left: -10px;
    color: #b3b3b3;
    top: 5px;
  }

  .footer-info {
    padding: 23px 0px 30px;
    border-top: 1px solid #233f65;
  }

  // {
  //   font-style: normal;
  //   font-weight: 700 !important;
  //   font-size: 32px;
  //   line-height: 40px;
  //   font-family: "Judson", serif;
  // }
}

.auth-login-form {
  .form-control {
    min-height: 50px;
    border: 1px solid #d8d6de;
    border-radius: 12px;
    background-color: transparent !important;
    // margin-bottom: 28px;
  }

  .password-box {
    margin-bottom: 6px;
  }

  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    // color: #cccccc;
  }

  .form-check-label.form-label {
    font-size: 13px;
  }

  .form-check-input:not(:checked):not(:indeterminate) {
    background-color: transparent;
  }

  // input.form-control:not(:focus) {
  //   border-color: #435f86;
  // }
}

.Client-text {
  font-family: "Plus Jakarta Sans", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  // color: #ffffff !important;
  margin-bottom: 28px !important;
}

.forgot-css {
  button {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    // color: #f8a78c;
    padding: 0;
    line-height: normal;
  }

  .btn-flat-primary:focus {
    color: #f8a78c;
  }

  .btn-flat-primary:active,
  .btn-flat-primary.active,
  .btn-flat-primary:focus {
    background-color: transparent;
  }
}

.login-btn {
  margin-top: 34px;
}

.login-welcome {
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 100%;
    // color: #ffffff;
    color: $black;
    margin-bottom: 16px;
    font-family: "Plus Jakarta Sans", serif;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  .platform-list {
    margin: 48px 0;

    ul {
      li {
        margin-bottom: 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        font-family: "Plus Jakarta Sans";
        color: $black;

        svg {
          margin-right: 11px;
        }
      }
    }
  }

  .help-member {
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      // color: #ffffff;

      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        // color: #f8a78c;
      }
    }
  }
}
.parent-welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 140px);
  .welcome-container {
    // margin-top: 96px;
    // margin-bottom: 180px;
    width: 100%;
  }
}

.main-login-header.container-fluid {
  padding-left: 34px;
  padding-right: 34px;
}

.BgShine {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 247px;
    height: 210px;
    left: -90px;
    top: -100.39px;
    // background: url(../images/svg/BgShine.svg);
    background: rgba(81, 243, 221, 0.4);
    filter: blur(100px);
    transform: rotate(-12.96deg);
    z-index: -1;
  }
}

.Forgot-password-modal {
  max-width: 554px;

  .resetText {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
    font-family: "Judson", serif;
    color: #f5693b;
  }

  p {
    color: #f5693b;
    margin-bottom: 28px;
  }

  .modal-body {
    padding: 64px;
  }

  .btn-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 23px;
  }

  .btn-flat-success:hover:not(.disabled):not(:disabled) {
    background-color: transparent;
  }

  input.form-control {
    border: 1px solid #435f86;
    border-radius: 12px;
    background-color: transparent !important;
    // margin-bottom: 28px;
    min-height: 50px;
  }

  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    // color: #cccccc;
  }
}

.modal .modal-content,
.modal .modal-body,
.modal .modal-footer {
  background-color: $white;
}

.dark-layout .modal .Forgot-password-modal .modal-content,
.dark-layout .modal .Forgot-password-modal .modal-body,
.dark-layout .modal .Forgot-password-modal .modal-footer {
  background-color: #0b1e38;
  border-color: #0b1e38;
  border-radius: 24px;
  // color: $white;
}

// Create Account Page
.register-page {
  & .register-col {
    max-width: 1140px;
    // min-height: 100vh;
    min-height: calc(100vh - 140px);
    padding: 100px 0 50px 0;
    overflow-y: unset;
  }

  h1 {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 72px;
    line-height: 100%;
    font-family: "Plus Jakarta Sans";
    letter-spacing: -0.005em;
    // color: #ffffff;
  }

  & .BgShine {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 247px;
      height: 210px;
      left: -90px;
      top: -100.39px;
      // background: url(../images/svg/BgShine.svg);
      background: rgba(81, 243, 221, 0.4);
      filter: blur(100px);
      // transform: rotate(-12.96deg);
    }
  }

  // .auth-inner {
  //   min-height: 100vh;
  //   padding: 100px 0;
  //   overflow-y: unset;
  // }
  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    // color: $white;
    display: block;
  }

  .form-control {
    min-height: 50px;
    border-radius: 12px;
    background-color: transparent !important;
    display: inline-flex;
  }

  & .dark-layout .input-group:focus-within .form-control,
  [dir] .dark-layout .input-group:focus-within .input-group-text {
    border: 1px solid #435f86;
  }

  // radio-wrapper
  .radio-wrapper {
    display: flex;
    margin: 16px 0 32px 0;
    flex-wrap: wrap;

    .form-check {
      padding-left: 8px;
      margin: 5px;
    }

    input[type="radio"] {
      position: absolute;
      visibility: hidden;
      display: none;
    }

    label {
      // color: lighten($primary, 40%);
      display: inline-block;
      cursor: pointer;
      font-weight: 300;
      font-size: 16px;
      border: 1px solid #d8d6de;
      padding: 16px 32px;
      border-radius: 100px;
      // color: $white;
      font-style: normal;
    }

    input[type="radio"]:checked + label {
      background: $primary;
      border: 1px solid $primary;
      color: $white;
    }
  }

  .treminfo {
    margin: 0 0 32px 0;
  }

  .register-page-video {
    padding-bottom: 50px;
  }
}

.btn-primary.uncut-button {
  background-color: $primary !important;
  border-color: $primary !important;
  padding: 18px 30px;
  max-width: 187px;
  max-height: 52px;
  // color: #0b1e38 !important;
  border-radius: 100px;
  text-transform: capitalize;

  &:hover {
    box-shadow: 0 8px 25px -8px $primary !important;
  }
}

.create-new-project-steps {
  & .create-project-steps {
    background-color: transparent !important;
    box-shadow: none !important;

    .bs-stepper-header {
      border: none;
      border-bottom: none !important;
    }

    .bs-stepper-header,
    .bs-stepper-content {
      padding: 0;

      margin: 0;

      .content {
        margin-top: 2em;
      }
    }

    & .accordion-body {
      padding: 20px 1rem;
    }
  }
}

// ** order-page

.order-accordian {
  display: flex;
  flex-wrap: wrap;

  & .order-info {
    min-width: 250px;

    & .badge-item {
      margin-top: 0.75rem;
    }

    & label {
      font-weight: 600;
      // margin-left: 20px;
      // color: $primary;
    }

    & h4 {
      color: $primary;
    }

    & .item-price {
      font-size: 1rem;
      margin-top: 0.75rem;
    }
  }
}

.order-price-box {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;

      .order-amt {
        // color: $white;
        font-weight: 700;
      }

      .order-amt-total {
        color: $primary;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }

  .suitable-package-link {
    text-align: center;

    background-color: transparent !important;
    border: 0;
    color: $primary !important;
    width: 100%;

    &:hover {
      box-shadow: none !important;
    }
  }
}

.order-box {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24) !important;
}

// Subscription

.plans-offer {
  & .section-title {
    font-size: 2.5rem;
  }

  & .pricing-card {
    & .card-body {
      padding: 32px 24px;
    }

    & .row > * {
      padding-right: calc(var(--bs-gutter-x) * 0.5715);
      padding-left: calc(var(--bs-gutter-x) * 0.5715);
    }

    & .card {
      background-color: unset;
      margin-bottom: 2.29rem;
      // background: linear-gradient(2.32deg, #4da692 -241.36%, #0a1d37 113.05%);
      background: #fff;
      height: calc(100% - 32px);
    }

    & h2 {
      font-weight: 700;
      font-size: 2.29rem;
      line-height: 40px;
      color: $black;
      margin-top: 15px;
    }

    & .price-div {
      display: flex;
      align-items: center;
    }

    & .price {
      color: $primary;
      font-weight: 700;
      font-size: 2.9rem;
      font-family: "Judson";
      line-height: 48px;
    }

    & .pricing-duration {
      color: #8198b8 !important;
    }

    & .middle-points {
      // background: $white;
      background: #ffcab9;

      & h4 {
        font-style: normal;
        font-family: "Judson";
        font-weight: 700;
        font-size: 25px;
        line-height: 32px;
        // color: #f5693b;
      }

      & p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        // color: #f5693b;
      }
    }

    & ul {
      list-style: none;
      padding-left: 0;

      & li {
        display: flex;
        align-items: center;
        padding: 8px 0;
        color: #fff;
        font-weight: 500;

        & svg {
          margin-right: 5px;
          max-width: 20px;
          max-height: 20px;
        }
      }
    }
  }
}

// Home Page
.main-video {
  margin: 50px 0;

  .col {
    max-height: 600px;
  }
}

.Welcome-info {
  padding: 80px 0;
  margin: 50px 0 0;
  text-align: center;
  background-color: #fff;
}

// end Home

// plan-info-modal

.plan-info-modal {
  display: flex;

  h5 {
    font-size: 1.2rem;
  }

  .note {
    color: $light-pink;
    font-size: 16px;
    line-height: normal;
  }
}

.breakdownCard {
  border: 1px solid #3b4253;
  margin-bottom: 10px;
}

.add-coin-price {
  font-size: 1.4rem;
  color: $primary !important;
}

.click-name {
  color: $primary !important;
}

// Client Detail
.client-detail {
  & .information {
    display: flex;
    align-items: center;
    padding: 15px 10px;

    & h2 {
      color: $primary;
      margin: 0 0;
      font-size: 1.5rem;
    }

    & h3 {
      padding-left: 10px;
      margin: 0 0;
      font-size: 1.5rem;
    }
  }
}

.project-table {
  overflow-x: scroll;
  // overflow-y: hidden;
}

.project-modal {
  .sound-card {
    h4 {
      color: $primary;
    }

    & svg {
      width: 18px;
    }
  }

  & .row > * {
    padding: 10px 12px;
  }

  .modal-header {
    .modal-title {
      font-size: 1.3rem;

      span {
        color: $primary;
      }
    }
  }

  .form-label {
    color: $primary;
    font-size: 1.2rem;
  }

  .information {
    display: flex;
    align-items: center;

    // padding: 0px 0px;
    & h4 {
      color: $primary;
      margin: 0 0;

      & span {
        color: $white;
      }

      // font-size: 1.3rem;
    }

    & h5 {
      padding-left: 10px;
      margin: 0 0;
      // font-size: 1.2rem;
    }
  }
}

.order-project-btn {
  background-color: $light-blue !important;
  border-color: $light-blue !important;
  // color: #0b1e38 !important;

  &:focus {
    background-color: $light-blue !important;
    border-color: $light-blue !important;
    // color: #0b1e38 !important;
  }
}

.dark-layout .app-user-view .plan-card.upcoming {
  border: 1px solid transparent !important;
}

.active-plan {
  // transform: scale(1.05);
  border: 2px solid $primary;
  // box-shadow: 0 0 10px 1px #4da69a !important;
}

.upcoming-plan {
  border: 1px solid #f8a78c;

  // box-shadow: 0 0 10px 1px #f8a78c !important;
}

.terms-modal-header {
  width: 100%;

  h5 {
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
}

.project-table {
  max-height: 650px;
  // overflow-x: scroll;
  // overflow-y: scroll;
  overflow: auto;

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.alert-banner {
  left: 0;
  right: 0;
  z-index: 9999;
  position: fixed;
  border-radius: 0;
  top: 0;
  color: #fff !important;
  background-color: #f44336 !important;
  border-color: #f44336;

  .alert-body {
    display: flex;
    justify-content: space-between;
  }
}

.break-word {
  word-break: break-word;
}

.card-badge {
  position: absolute;
  right: 13px;
  top: 18px;
}

@media (max-width: 1660px) {
  .nav-bar {
    .nav-link {
      margin-right: 16px;
    }
  }
}

@media (max-width: 1470px) {
  .nav-bar {
    .nav-link {
      margin-right: 11px;
    }
  }
}

@media (max-width: 1400px) {
  .welcome-container {
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .nav-bar {
    svg {
      rect {
        width: 175px;
        height: 105px;
      }
    }

    .container-fluid .logo {
      text-align: start;
    }

    .nav-link {
      padding: 0.358rem 0rem;
      font-size: 15px;
      margin-right: 22px;
    }

    .menu-Dropdown {
      font-size: 15px;
      padding: 0 0;
    }
  }

  .register-page {
    .register-col {
      padding: 30px;
    }
  }
}

@media (max-width: 1365px) {
  .pricing-calc {
    .order-box {
      .price-details {
        .price-detail {
          .detail-title {
            max-width: 180px;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .subscriptionTitle {
    flex-direction: column;
    align-items: flex-start !important;

    & span {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 1200px) {
  .nav-bar {
    .nav-link {
      font-size: 12px;
      margin-right: 16px;
    }

    .menu-Dropdown {
      font-size: 12px;
    }
  }

  .pricing-calc {
    .order-box {
      .price-details {
        .price-detail {
          .detail-title {
            max-width: inherit;
          }
        }
      }
    }
  }

  .subscriptionTitle {
    flex-direction: row;
    align-items: center !important;

    & span {
      margin-left: 0.75rem !important;
    }
  }
}

@media (max-width: 992px) {
  // .nav-bar {
  //   .nav-link {
  //     font-size: 12px;
  //     margin-right: 16px;
  //   }
  //   .menu-Dropdown {
  //     font-size: 12px;
  //   }
  // }
  .BgShine::before {
    display: none;
  }

  .navbar-wrap {
    & .container-fluid {
      display: flex;
      align-items: center;
    }
  }

  .register-page {
    h1 {
      font-size: 4em;
    }
  }

  .canvas-header {
    padding: 15px;
    justify-content: end;
  }

  .canvas-body {
    .nav-link,
    .btn-group {
      margin: 12px;
    }
  }

  .Forgot-password-modal .modal-body {
    padding: 30px;
  }

  .Forgot-password-modal .resetText {
    margin-top: 35px;
    font-size: 35px;
  }
}

@media (max-width: 767px) {
  .Forgot-password-modal .resetText {
    margin-top: 25px;
    font-size: 35px;
  }
  .custom-email-verify {
    h1 {
      font-size: 3em;
    }
  }
  .vertical-layout
    .header-navbar:not(.navbar-horizontal)
    .navbar-container
    ul.navbar-nav
    li.dropdown.dropdown-user
    .dropdown-menu,
  .horizontal-layout
    .header-navbar:not(.navbar-horizontal)
    .navbar-container
    ul.navbar-nav
    li.dropdown.dropdown-user
    .dropdown-menu {
    top: 5rem !important;
  }
}

@media (max-width: 600px) {
  .main-login-header.container-fluid {
    padding-left: 10px;
    padding-right: 10px;
  }

  .blinkova-logo {
    h2 {
      display: none;
    }
  }

  .login-wrapper {
    max-width: unset;
  }

  .login-btn {
    margin-top: 20px;
  }

  .themeBtn {
    &.btn-primary {
      min-height: 40px;
    }
  }

  .LoginFooter {
    .btn {
      margin-left: 0;
    }
  }

  // .nav-bar svg rect {
  //   width: 150px;
  //   height: 85px;
  // }
  .nav-bar .container-fluid .logo {
    align-items: center;
    display: flex;
  }

  .header-btn {
    padding: 0.786rem 1rem;

    font-size: 12px;
  }

  // .register-page {
  //   h1 {
  //     font-size: 3em;
  //   }
  // }
  .register-page .radio-wrapper .form-check {
    padding-left: 0;
  }

  // Client Detail
  .client-detail {
    & .information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px 0px;

      & h2 {
        color: $primary;
        margin: 0 0;
        font-size: 1.3rem;
      }

      & h3 {
        padding-left: 0px;
        margin: 0 0;
        font-size: 1.3rem;
      }
    }
  }

  .subscriptionTitle {
    flex-direction: column;
    align-items: flex-start !important;

    & span {
      margin-left: 0 !important;
    }
  }

  .plans-offer {
    .pricing-card {
      h2 {
        margin-top: 12px;
      }
    }
  }
}

.order-title {
  max-width: 245px;
}

.plans-offer {
  .ala-carte {
    padding: 15px 0;
    height: 100%;
    border-radius: 1.5rem;

    .card-header {
      padding: 10px 0 0;
      border-bottom: 1px solid $primary;
    }

    h2 {
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;
    }

    h4 {
      font-size: 1.45rem;
      padding: 12px 0 0;
    }
  }
}

@media (max-width: 576px) {
  .container-sm,
  .container {
    max-width: 576px;
  }
  .plans-offer .container {
    max-width: 100%;
  }

  .custom-iframe {
    height: 400px;
  }

  .plans-offer .btn-text {
    font-size: 14px;
  }

  .pricing-calc {
    .order-price-box ul li {
      align-items: flex-start;
      flex-direction: column;

      .order-amt {
        margin-top: 2px;
      }
    }
  }

  .ElementsApp .InputElement {
    font-size: 14px !important;
  }

  .custom-accordion .accordion-sub-header {
    flex-direction: column;

    & .number-project {
      margin: 5px 0 !important;
    }
  }
}

@media (max-width: 400px) {
  .header-btn {
    padding: 0.8rem 0.5rem;
  }

  .order-accordian {
    & .order-info {
      min-width: auto;
    }
  }

  .header-carats-balance {
    h6 {
      font-size: 12px !important;
    }
  }
}

@media (max-width: 375px) {
  .deduct-button {
    margin-top: 14px;
  }

  .custom-iframe {
    height: auto;
  }
}

.no_data {
  width: 100%;
  // background-color: #ffffff;
  border-radius: 8px;

  p {
    margin: 0;
    padding: 15px;
    font-weight: 700;
    // text-align: center;
    font-size: 16px;
  }
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #343d55;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

// Badge icon class

.filterbadge {
  .cross-icon {
    border: 1px solid;
    padding: 3px;
    border-radius: 50%;
    margin: 0 0 0px 10px;
  }
}

// radio-company

.radio-company.register-page {
  .radio-wrapper {
    margin: 0 0;

    label {
      padding: 10px 32px;
    }
  }

  .form-label {
    font-size: 12px;
  }
}

.chat-div {
  display: flex;
  flex-direction: column-reverse;
}

.custom-feedback-msg {
  word-break: break-all;

  a {
    color: blue;

    :hover {
      color: blue;
    }
  }
}

.list-feedback-msg {
  p {
    max-width: 240px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0px;
    margin: 0px;
  }
}

.description-list {
  span {
    max-width: 350px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    padding: 0px;
    margin: 0px;
  }
}

.custom-project-table-width {
  max-width: 170px;
  word-wrap: break-word;
  display: -webkit-box;
}

.custom-project-type-td {
  min-width: 260px;

  @media (max-width: "400px") {
    min-width: max-content !important;
  }
}

// start datepicker css
.custom-datepicker-popper {
  .react-datepicker {
    &.custom-datepicker-calendar {
      // background: #0b1e38;
      // border-color: #0b1e38;
      box-shadow: none;
      min-width: 300px;
      font-size: 1rem;

      .react-datepicker__header {
        text-align: center;
        // background-color: #061e37;
        // border-bottom: 1px solid #061e37;
        padding: 8px 0;
      }

      .react-datepicker__day--disabled,
      .react-datepicker__month-text--disabled,
      .react-datepicker__quarter-text--disabled,
      .react-datepicker__year-text--disabled {
        // color: #656d74 !important;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        // color: #fff;
      }

      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover,
      .react-datepicker__quarter-text:hover,
      .react-datepicker__year-text:hover {
        // background-color: #283046;
      }

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        // color: #fff;
        font-weight: bold;
        font-size: 0.944rem;
        margin-bottom: 10px;
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        border-radius: 0.3rem;
        background-color: $primary;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 2.7rem;
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: $primary;
      }
    }
  }

  select {
    word-wrap: normal;
    // background: #273045;
    border: 0;
    padding: 5px 10px;
    border-radius: 3px;
    // color: #fff;
  }
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
  border-top-color: #011e3600 !important;
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper.custom-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
  border-top-color: #061e37 !important;
}

.react-datepicker-popper.custom-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #011e36 !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #011e36 !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  left: -15px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

// END Date

//
.back-div {
  align-items: center;
  margin-bottom: 16px;

  .content-header-left {
    margin-bottom: 0 !important;

    .breadcrumb-wrapper nav li.breadcrumb-item p {
      margin-bottom: 0;
    }
  }
}

.accordion-margin {
  .accordion-button:hover {
    z-index: 0;
  }
}

form.chat-app-form {
  align-items: flex-end;
  display: flex;
}

.chat-wrapper {
  width: 80%;
  display: inline-flex;
  align-items: self-end;

  .form-control {
    border-radius: 0.357rem !important;
  }
}

textarea {
  resize: none;
  box-sizing: border-box;
  height: auto;
  min-height: 41px;
  max-height: 97px;
  width: 387px;
  border: 1px solid #e4e7ec;
  border-radius: 20px;
  background-color: #f9fafb;
  outline: none;
  padding: 0 24px 0 24px;
  overflow: auto;
}

// Client Management
@media (max-width: 450px) {
  .for-client-table {
    margin-right: 0px;
    padding: 8.5px 10.5px !important;
  }
}

//
.calculator-div {
  .calculator-info h3 {
    font-size: 16px;

    @media (max-width: 600px) {
      width: 70%;

      .pricing-info {
        width: 30%;
        display: flex;
      }
    }
  }
}

// chat message color
.chat-content {
  color: white;
  /* Set the color for normal text */
  padding: 0;
  margin: 0;
}

.chat-content span {
  word-break: break-word;
}

.chat-content a {
  color: blue;
  /* Set the color for links */
  padding: 0;
  margin: 0;
}

.custom-feedback-msg a {
  color: inherit;
  text-decoration: underline;
}

.custom-feedback-msg a:hover {
  color: inherit;
  text-decoration: none;
}

// order page selected items

.Item-selected {
  // background: #0b1e38;
  border-radius: 5px;
  padding: 0px 5px;
  // margin: 0 5px;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
  //   rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  .price-detail {
    gap: 10px;
    &:last-child {
      border-bottom: 0px solid #3b4253;
    }

    border-bottom: 1px solid #3b4253;

    .order-title {
      max-width: 245px;
      font-size: 12px;
    }

    .order-amt {
      display: flex;
      max-width: 195px;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .badge {
      margin-bottom: 5px;
      margin-right: 2px;
    }
  }
}

.cart-items {
  overflow-y: auto;
  max-height: 240px;
}

.StripeElement.StripeElement--webkit-autofill {
  background-color: transparent !important;
  color: $primary !important;
}

.ElementsApp.is-autofilled .InputElement:not(.is-empty),
.ElementsApp.is-autofilled .InputElement:-webkit-autofill {
  color: $primary !important;
}

.chat-with-icon {
  display: flex;
  align-items: center;

  // p {
  //   clear: both;
  //   border-left: 1px solid #b4b7bd;
  //   padding-left: 7px !important;
  // }
  .chat-icon-tb {
    color: #b4b7bd;
    padding-right: 5px;
    // svg path {
    //   fill: #009688;
    // }
  }
}

.errorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.word-break-all {
  word-break: break-all;
}

.orange-btn {
  color: black !important;
  background-color: #f8a78c !important;
  border-color: #f8a78c !important;

  &:focus {
    background-color: #f8a78c !important;
    border-color: #f8a78c !important;
  }
}

// user card action btn
.card-action-btn {
  @media (min-width: 601px) {
    display: flex;
    min-width: 238px;
    margin-left: 10px;
  }

  button {
    @media (max-width: 600px) {
      margin-bottom: 5px;
    }
  }
}

.add-card-btn {
  @media (max-width: 375px) {
    margin-top: 10px;
  }
}

// .terms-modal {
//   h6 {
//     color: $white;
//   }
// }

[dir] .main-menu .navbar-header .navbar-brand {
  margin-top: 1.08rem;
}

.h6-span {
  margin-top: 0px;
  margin-bottom: 1rem;
  color: rgb(180, 183, 189);
  font-weight: normal;
  line-height: 1.5rem;
}

textarea.form-control.custom-text-area {
  max-height: max-content !important;
}

// .accordion-button {
//   .number-project {
//     display: none;
//   }
// }

// .accordion-button.collapsed {
//   .number-project {
//     display: block;
//   }
// }

.custom-accordion {
  .accordion-button::after {
    content: none;
  }
}

.custom-accordion {
  .dark-layout .accordion-button:before {
    background-image: url(../images/svg/accordion-botton.svg);
  }

  .accordion-button::before {
    margin-right: 25px;
  }

  .accordion-button::before {
    background-image: url(../images/svg/accordion-botton.svg);
    background-repeat: no-repeat;
    background-size: 1rem;
  }

  .accordion-button::before {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    content: "";
    transition: transform 0.2s ease-in-out;
  }

  .accordion-button:not(.collapsed)::before {
    transform: rotate(-180deg);
  }
}

.table-custom-column {
  color: #f8a78c !important;
}

// Start Style-Quill
.custom-quill-editor {
  color: #000000b2;
  .quill {
    .ql-toolbar,
    .ql-container,
    .ql-header.ql-picker {
      border-color: #3b4253 !important;
    }

    .ql-toolbar {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      .ql-formats {
        svg .ql-stroke {
          stroke: #727c8a;
        }

        .ql-underline {
          margin-left: 15px;
        }

        .ql-header.ql-picker {
          .ql-picker-label {
            color: #727c8a;
          }

          .ql-picker-label.ql-active {
            color: #000;
          }

          .ql-picker-item[data-value="6"]::before {
            font-size: 0.8em;
          }

          .ql-picker-item[data-value="5"]::before {
            font-size: 1em;
          }
        }

        .ql-active {
          svg .ql-stroke {
            stroke: #000;
          }
        }
      }
    }

    .ql-container {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      .ql-editor {
        font-size: 14px !important;

        a,
        a strong {
          text-decoration: none;
          color: #3ba69a !important;
        }

        // Start text-style
        p strong {
          color: #000;
          font-weight: 500;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.14px;
          line-height: 21px;
        }

        h6,
        h5 {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.14px;
          line-height: 21px;
        }

        h6 strong,
        h5 strong {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.14px;
          line-height: 21px;
        }

        // End text-style
      }
    }
  }
}

.dynamic-popup-data {
  color: #000000b2;
  .popup-data-example-label {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;

    h3 {
      // text-decoration: underline;
      font-weight: 700;
      color: #fe6944;
    }
  }

  .sub-component-dynamic-popup-data {
    border: 1px solid #6a748b !important;
    border-radius: 15px;
    padding: 18px;

    // start popup-text-style
    .main-text {
      a strong {
        text-decoration: none;
        color: #3ba69a !important;
      }

      p strong {
        font-weight: 500;
        color: #000;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.14px;
        line-height: 21px;
      }

      h6 {
        color: #000;
        font-size: 14px;
        letter-spacing: 0.14px;
        line-height: 1.2;
        margin-bottom: 7px;
      }

      h6 strong {
        color: #000;
        font-size: 14px;
        letter-spacing: 0.14px;
        line-height: 1.2;
        margin-bottom: 7px;
        font-weight: 500;
      }

      h5 {
        // color: #fff;
        font-size: 1rem;
        letter-spacing: 0.14px;
        line-height: 1.2;
        margin-bottom: 14px;
      }

      h5 strong {
        // color: #fff;
        font-size: 1rem;
        font-weight: 600 !important;
        letter-spacing: 0.14px;
        line-height: 1.2;
        margin-bottom: 14px;
      }

      p:nth-last-child(1) {
        margin-bottom: 0px !important;
      }
    }

    // end popup-text-style
  }
}

// End Style-Quill\

.region-select {
  .select__control {
    padding: 5px;
  }
}

.dynamic-popup-data-print-user {
  // start popup-text-style
  .main-text {
    a strong {
      text-decoration: none;
      color: #3ba69a !important;
    }

    p strong {
      font-weight: 500;
      // color: #fff;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.14px;
      line-height: 21px;
    }

    h6 {
      // color: #fff;
      font-size: 14px;
      letter-spacing: 0.14px;
      line-height: 1.2;
      margin-bottom: 7px;
    }

    h6 strong {
      // color: #fff;
      font-size: 14px;
      letter-spacing: 0.14px;
      line-height: 1.2;
      margin-bottom: 7px;
      font-weight: 500;
    }

    h5 {
      // color: #fff;
      font-size: 1rem;
      letter-spacing: 0.14px;
      line-height: 1.2;
      margin-bottom: 14px;
    }

    h5 strong {
      // color: #fff;
      font-size: 1rem;
      font-weight: 600 !important;
      letter-spacing: 0.14px;
      line-height: 1.2;
      margin-bottom: 14px;
    }

    p:nth-last-child(1) {
      margin-bottom: 0px !important;
    }
  }
}

.custom-pagination {
  margin-top: 16px;
  .page-item.active .page-link {
    z-index: 1;
  }
}

.react-datepicker-popper {
  z-index: 99;
}

.custom-project-table-width.project-name-table {
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.on-hover-table-data p {
  transition: 0.3s;
}

.on-hover-table-data:hover p {
  color: $primary;
}

// start

.custom-accordion {
  overflow: auto;

  // height: 70vh;

  .accordion-button::after {
    content: none;
  }

  .accordion {
    // width: max-content;

    position: relative;

    z-index: 1;

    .custom-accordion-header {
      position: sticky;

      top: 0;

      z-index: 99;
    }

    .accordion-item {
      background: transparent;

      border-left: 6px solid;

      border-radius: 6px !important;

      border-bottom: none;
    }

    .accordion-button {
      background: #fff !important;
    }
  }

  .accordion-item {
    margin-top: 10px;
  }

  .accordion-sub-header {
    display: flex;

    .number-project {
      margin: 5px 0 0 10px;
    }
  }

  .main-project-table-accordion-body {
    .custom-project-table {
      .table-head {
        position: sticky;

        top: 55px;

        z-index: 99;
      }
    }
  }
}

.custom-accordion {
  .dark-layout .accordion-button:before {
    background-image: url(../images/svg/accordion-botton.svg);
  }

  .accordion-button::before {
    margin-right: 25px;
  }

  .accordion-button::before {
    background-image: url(../images/svg/accordion-botton.svg);

    background-repeat: no-repeat;

    background-size: 1rem;
  }

  .accordion-button::before {
    flex-shrink: 0;

    width: 1rem;

    height: 1rem;

    content: "";

    transition: transform 0.2s ease-in-out;
  }

  .accordion-button:not(.collapsed)::before {
    transform: rotate(-180deg);
  }
}

// end

// .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
//   border: 0px !important;
// }

// ag-start

.ag-style {
  transition: 0.3s !important;
}

.ag-style:hover {
  color: $primary !important;
}

.list-feedback-msg .badge.badge-up {
  top: 0px;
}

.badge.badge-up.my-project-table-icon {
  top: -7px;
}

.text-wrap-review-link a {
  max-width: 150px !important;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ag-cell-editor {
  display: flex;
  align-items: center;
}

// ag-end
// [dir=ltr] .ag-ltr .ag-cell {}
.break-word-class {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
}

// .ag-header-cell-label {
//   justify-content: center;
// }

// .ag-cell-value {
//   justify-content: center;
// }

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
[dir] .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
[dir] .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
[dir] .ag-ltr .ag-cell-range-single-cell,
[dir] .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
[dir] .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
[dir] .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
[dir] .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
[dir] .ag-rtl .ag-cell-range-single-cell,
[dir] .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid transparent !important;
}

.link-class {
  padding: 0.571rem 1rem;
  border: 1px solid #d8d6de;
  border-radius: 4px;
  background-color: #efefef;

  a {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    word-wrap: break-word;
    -webkit-line-clamp: 1;
  }
}

.ag-header.ag-header-allow-overflow .ag-header-row {
  text-transform: uppercase;
}

.ag-date-picker {
  height: 50px !important;
}

.cell-class {
  // display: grid !important;
  // align-items: center;
  // display: flex;
  // align-items: center;
}

// #requested .ag-row {
//   border-bottom: 1px solid red !important;
// }

.custom-loader-ag .ag-overlay-loading-wrapper {
  // background-color: #283046 !important;

  .ag-overlay-loading-center {
    box-shadow: none !important;
    background: transparent !important;
    border: none !important;
  }
}

.custom-ag-grouping {
  .admin-badge {
    top: 3px !important;
    left: 16px !important;
    min-width: unset;
    min-height: unset;
    padding: 6px !important;
  }

  .ag-watermark {
    display: none;

    .ag-opacity-zero::after {
      content: none;
      display: none;
    }

    .ag-opacity-zero::before {
      content: none;
      display: none;
    }
  }

  .ag-header {
    // background-color: #343d55;
    // background-color: #eee;
  }

  .ag-root-wrapper {
    border: none !important;
  }

  // .ag-theme-quartz {
  // background-color:red
  // }
}

.ag-theme-quartz .ag-menu,
.ag-theme-quartz-dark .ag-menu,
.ag-theme-quartz-auto-dark .ag-menu {
  // background-color: #283046 !important;
  // color: color-mix(in srgb, transparent, var(--ag-foreground-color) 95%);
}

.estimated-completion-date {
  .react-datepicker-wrapper {
    line-height: 28px;
  }

  .custom-group-date {
    padding: 4.5px 10px;
    height: 28px;
    line-height: 28px;
    background-color: transparent !important;
  }
}

.review-link-custom,
.actions-archive-delete,
.footage {
  display: flex !important;
  align-items: center !important;
}

.grouping.btn.btn-icon {
  padding: 3px;
}

.feedback-grouping {
  .chat-with-icon {
    height: auto !important;
  }
}

.ag-loading.ui-loader.show .overlay {
  // background-color: #283046;
}

.custom-upload-button {
  padding: 0px !important;

  .label-upload {
    cursor: pointer !important;
    padding: 0.486rem 1rem !important;
  }
}

.for-client-table {
  margin-right: 16px;
}

// .ag-header-group-cell{

// }

.project-management .ag-ltr .ag-cell {
  // color: #adadad;
}

.client-management-tables .click-name,
.archive-table .click-name,
.editor-master .click-name,
.coworkers-table .click-name {
  color: $primary !important;
}

.client-management-tables .table-custom-column,
.archive-table .table-custom-column,
.editor-master .table-custom-column,
.coworkers-table .table-custom-column {
  color: $primary !important;
}

.client-management-tables tbody td,
.archive-table tbody td,
.editor-master tbody td,
.coworkers-table tbody td {
  // color: #adadad !important;
}

.for-client-table {
  margin-right: 16px;
}

.padding-bottom-1px .accordion-body {
  padding-bottom: 1px;
}

.custom-tooltip .tooltip-inner {
  background-color: #0b1e38;
  width: 100%;
}

.blinkovo-logo {
  margin: 0px !important;
  color: $black;
  text-transform: lowercase;
  letter-spacing: -0.04em;
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
}

.react-select .select__multi-value {
  margin: 3px !important;
  background-color: transparent !important;
  color: $primary;
  border: 1px solid $primary !important;
}

.select__multi-value .select__multi-value__label {
  color: $primary !important;
}

.blinkova-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 36px;
    margin-right: 5px;
  }
  h2 {
    color: $primary;
  }
}

.auth-wrapper .nav-link:hover,
.login-welcome .help-member p a:hover {
  opacity: 0.7;
}

.custom-payment-modal .modal,
.custom-payment-modal .modal-body {
  background-color: #fff;
}

.color-input-wrapper {
  height: 25px;
  width: 25px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.color-input-wrapper input[type="color"] {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: none;
  margin: 0;
  padding: 0;
}

.modal .modal-header.btn-loading .btn-close {
  transform: translate(9px, -4px) !important;
}

.custom-email-verify {
  min-height: calc(var(--vh, 1vh) * 84) !important;
  max-width: 750px;
  .resend-btn {
    cursor: pointer;
    color: $primary;
    font-weight: 600;
  }

  .resend-btn.disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: default;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 32px;
    margin-top: 24px;
  }
}

.text-btn {
  cursor: pointer;
  color: $primary;
  font-weight: 600;
}

.main-menu.menu-light .navigation .navigation-header {
  margin: calc(0.9rem + 0.286rem) 0 0.8rem 2.2rem;
}

.to-do-info {
  ol {
    padding: 0;
    margin: 15px;
    padding-left: 15px;
  }
  ol li::marker {
    font-weight: 600;
  }
  li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: dotted 1px #cfc5c3;
  }
  li:last-child {
    border-bottom: dotted 0px #cfc5c3;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  button {
    padding: 11px;
    max-width: 200px;
    width: 100%;
  }
}

// .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a svg,
// .vertical-layout.vertical-menu-modern .main-menu .navigation > li > a i {
//   height: 16px !important;
// }
